import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles/style.scss';

import ThreeCanvas from './components/ThreeCanvas';
import ThreeCanvasOptimized from './components/ThreeCanvasOptimized';

import LanguageSwitcher from './components/LanguageSwitcher';

import './js/i18n';
import LoaderFake from './components/Loader';
import LoaderFakeCss from './components/LoaderFakeCss';


import Loader from './components/Loader';
import AboutSection from './components/AboutSection';
import AlbumSection from './components/AlbumSection';
import ButtonBack from './components/ButtonBack';
import MusicBackground from './components/MusicBackground';
import Menu from './components/Menu';
import GrainBackground from './components/GrainBackground';



function App() {

  /*   const [isLoaded, setIsLoaded] = useState(false);
  
    // Simulate the loading of Three.js canvas
    useEffect(() => {
    
      const timer = setTimeout(() => {
        setIsLoaded(true);
      }, 2000); 
  
      return () => clearTimeout(timer);
    }, []);
   */

  useEffect(() => {
    window.addEventListener(
      'contextmenu',
      function (e) {
        e.preventDefault();
      },
      false
    );
  })

  return (

    <Router>
      {/*   <Loader /> */}
      {/*  <LoaderFake /> */}

      <LoaderFakeCss />
      {/*  <GrainBackground /> */}

      <MusicBackground />
      <LanguageSwitcher />

      {/*  <div className={`fade-in ${isLoaded ? 'loaded' : ''}`}>
        <ThreeCanvasOptimized />
      </div> */}

      <ThreeCanvasOptimized />

      <ButtonBack />

      {/*  <ThreeCanvas />  */}

      <Menu />

      <Routes>
        <Route path="/about" element={<AboutSection />} />
        <Route path="/album" element={<AlbumSection />} />
      </Routes>

    </Router>

  );
}

export default App;
